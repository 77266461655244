
const likelihoods = [{
    "label": "0 - Undecided",
    "id": "0 - Undecided"
}, {
    "label": "1 - Extremely Improbable",
    "id": "1 - Extremely Improbable"
}, {
    "label": "2 - Improbable",
    "id": "2 - Improbable"
}, {
    "label": "3 - Probable",
    "id": "3 - Probable"
}, {
    "label": "4 - Frequent",
    "id": "4 - Frequent"
}, {
    "label": "5 - Almost Certain",
    "id": "5 - Almost Certain"
}];

const consequences = [{
    "label": "0 - Undecided",
    "id": "0 - Undecided"
}, {
    "label": "1 - Negligible",
    "id": "1 - Negligible"
}, {
    "label": "2 - Minor",
    "id": "2 - Minor"
}, {
    "label": "3 - Moderate",
    "id": "3 - Moderate"
}, {
    "label": "4 - Major",
    "id": "4 - Major"
}, {
    "label": "5 - Catastrophic",
    "id": "5 - Catastrophic"
}];

export const addSafetyManagementForm = ({ form, store, db}) => {
    
    const safteyForm = Object.values(store.state["Database/DocumentTypes"]).find(doc => doc.NameShort === "SF");
    const nextCount = safteyForm.Count + 1;
    
    form.setConfig({
        "title": "Add Saftey Management Form",
        "type": "push",
        "location": "SafetyManagement/Forms",
        "fields" :[{
            "label": "Diescription",
            "name": "Description",
            "type": "text",
            "required": true,
            "divClass": "md:col-span-3"
        }, {
            "label": "Category",
            "name": "Category",
            "type": "dropdown",
            "dataLocation": "SafetyManagement/Categories",
            "required": true,
        }, {
            "label": "Likelihood",
            "name": "Likelihood",
            "type": "dropdown",
            "required": true,
            "dropdownData": likelihoods
        }, {
            "label": "Consequence",
            "name": "Consequence",
            "type": "dropdown",
            "required": true,
            "dropdownData": consequences
        }],
    });
    form.getDropdownData();
    console.log("Adding Safety Management Form", form);
    form.toggle();
};

