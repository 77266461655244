import ItemListView from "../../components/ItemListView/ItemListView.vue";
import ItemView from "../../components/ItemView/ItemView.vue";
import { addSafetyManagementForm } from "./actions";

export default [{
    "path": "/SafetyManagement/Forms",
    "component": ItemListView,
    "name": "Safety Management Forms",
    "meta": {
        "app": "SafetyManagement",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 7,
        "sideNavPosition": 7,
        "sideNavSection": "Safety Management",
        "icon": "helmet-safety",
        "name": "Safety Management Forms",
        "tableSettings": "SafetyManagementForms",
        "dataPath": "SafetyManagement/Forms",
        "storeData": ["Database/DocumentTypes"],
        "selectionRoute": "SafetyManagement/Forms/{ID}",
        "menuActions": [{
            "label": "Add Safety Management Form",
            "action": addSafetyManagementForm
        }]
    }
}, {
    "path": "/SafetyManagement/Forms/:id",
    "component": ItemView,
    "name": "Safety Management Form",
    "meta": {
        "app": "SafetyManagement",
        "dataPath": "SafetyManagement/Forms",
        "title": (item) => `Safety Management Form: ${item.Number}`,
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Category",
            "key": "Category"
        }, {
            "label": "Likelihood",
            "key": "Likelihood"
        }, {
            "label": "Consequence",
            "key": "Consequence"
        }, {
            "label": "Date Added",
            "key": "Date.Created",
            "type": "date"
        }, {
            "label": "Date Reviewed",
            "key": "Date.Reviewed",
            "type": "date"
        }]
    }
}]
